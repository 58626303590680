<template>
  <div class="magazine-page">
    <div class="contents">
      <p class="desc">이모션글로벌의 시선으로<br />세상을 이야기합니다.</p>
    </div>
    <div class="list-wrap" ref="listWrap" :style="{height: `${listWidth}px`}">
      <div class="list-scroll" ref="listScroll">
        <div class="list" ref="list">
          <ul ref="listUl" :style="{width: `${listWidth}px`}">
            <li v-for="(item, index) in listData" :key="item.id">
              <router-link :to="`/magazine/${item.id}/?list=${totalLength - index}`">
                <span class="list-num" v-if="listData.length">
                  {{ totalLength - index }}
                </span>
                <div class="list-thumb">
                  <img :src="imgUrl(item.pcKeyPicturePhysicalName)" :alt="item.magazineTitle" />
                </div>
                <div class="list-cont">
                  <i></i>
                  <div class="list-cont-tit" v-html="handleNewLine(item.magazineTitle)"></div>
                  <div class="list-cont-date">{{ item.reportingDate | dateFormat }}</div>
                  <div class="list-cont-desc" v-html="handleNewLine(item.magazineDesc)"></div>
                  <span class="list-cont-more">Read More</span>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {TweenMax, TimelineMax, Power0} from "gsap";
  import {mapGetters} from "vuex";
  import {apiMagazineList} from "@/api";
  import {commonMethods} from "@/utils/common-methods";
  export default {
    name: "magazineList",
    data() {
      return {
        page: 0,
        isInit: false,
        totalLength: 0,
        size: 10,
        totalPage: 0,
        keyword: "",
        listWidth: 0,
        scene: null,
        listData: [],
        loading: false
      };
    },
    computed: {
      ...mapGetters(["GE_CONT_HEIGHT"])
    },
    watch: {
      GE_CONT_HEIGHT() {
        //this.getListWidth();
      }
    },
    mixins: [commonMethods],
    methods: {
      infiniteScroll() {
        if (
          this.page < this.totalPage &&
          !this.loading &&
          !(this.GE_CONT_HEIGHT - 1000 >= window.scrollY + window.innerHeight)
        ) {
          this.page = this.page + 1;
          this.fetchData();
        }
      },
      async fetchData() {
        this.loading = true;
        try {
          const {
            data: {data: response}
          } = await apiMagazineList({
            page: this.page,
            size: this.size,
            keyword: this.keyword
          });
          this.totalPage = response.totalPages;
          this.listData = this.listData.concat(response.content);
          this.$nextTick(() => {
            this.getListWidth();
          });

          // console.log( this.totalPage - 1, response2.totalPages, response2.content.length );

          if (!this.isInit) {
            this.totalLength = response.totalElements;
            this.isInit = true;
          }
        } catch (error) {
          console.error(error.response);
        }
      },
      getListWidth() {
        this.listWidth = 0;
        this.$refs.listUl.querySelectorAll("li").forEach(el => {
          this.listWidth =
            this.listWidth + el.clientWidth + parseInt(window.getComputedStyle(el).marginLeft);
        });
        this.pageAnimation();
      },
      pageAnimation() {
        this.scene?.destroy(true);
        const timeline = new TimelineMax();
        const item = this.$refs.listWrap;
        const inner = this.$refs.listScroll;
        const tween = TweenMax.to(inner, 1, {
          ease: Power0.easeInOut,
          y: this.listWidth - inner.clientHeight + 30
        });
        const item2 = this.$refs.list;
        const inner2 = this.$refs.listUl;
        const tween2 = TweenMax.to(inner2, 1, {
          ease: Power0.easeInOut,
          x: item2.clientWidth - this.listWidth + 30
        });
        timeline.add([tween, tween2]);
        this.scene = this.$scrollmagic
          .scene({
            triggerElement: item,
            triggerHook: 0.1,
            duration: Math.max(this.listWidth - inner.clientHeight, 0) || 0
          })
          .setTween(timeline);
        window.removeEventListener("scroll", this.infiniteScroll);
        window.addEventListener("scroll", this.infiniteScroll);
        this.$scrollmagic.addScene(this.scene);
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
    },
    created() {
      this.fetchData();
    },
    activated() {
      this.getListWidth();
    },
    destroyed() {
      window.removeEventListener("scroll", this.infiniteScroll);
      //this.scene?.destroy(true);
    }
  };
</script>
<style lang="scss" scoped>
  .magazine-page {
    padding-top: 200px;
  }
  .desc {
    position: relative;
    font-size: 70px;
    line-height: 100px;
    letter-spacing: -0.03em;
    font-weight: bold;
    &:after {
      content: "";
      position: absolute;
      top: 102px;
      left: 916px;
      height: 64px;
      width: 64px;
      border-radius: 50%;
      background: $red;
    }
  }
  .list-wrap {
    //margin-top: 100px;
    height: auto;
    width: 100%;
    min-width: $globalWidth;
    overflow: hidden;
  }
  .list-scroll {
    @include globalWidth();
    padding-top: 100px;
    ul {
      display: flex;
      width: auto;
      li {
        overflow: hidden;
        flex: 0 0 auto;
        height: 100vh;
        min-height: 1000px;
        width: 550px;
        a {
          display: block;
        }
        .list-num {
          @include montserrat;
          font-weight: 700;
          font-size: 48px;
          color: #000;
        }
        .list-thumb {
          overflow: hidden;
          width: 100%;
          height: 360px;
          border-radius: 20px;
          //height: 309px;
          margin: 20px 0 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s ease-in-out;
          }
        }
        .list-cont {
          position: relative;
          margin: 30px 0 0;
          padding: 27px 0 0;
          color: #000;
          i {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #ee2c3c;
          }
          &-tit {
            min-height: 120px;
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            letter-spacing: -0.05em;
          }
          &-date {
            @include montserrat;
            margin: 16px 0 0;
            font-size: 14px;
            line-height: 22px;
            color: #b7b7b7;
          }
          &-desc {
            overflow: hidden;
            min-height: 72px;
            height: 72px;
            margin: 10px 0 0;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.05em;
          }
          &-more {
            @include montserrat;
            display: inline-block;
            position: relative;
            margin: 30px 0 0;
            font-size: 14px;
            line-height: 20px;
            transition: all 0.3s ease-in-out;
            &::before {
              display: block;
              content: "";
              position: absolute;
              bottom: 0;
              right: 0;
              left: 0;
              height: 1px;
              background: #000;
              transition: all 0.3s ease-in-out;
            }
            &::after {
              display: block;
              content: "";
              position: absolute;
              right: 0;
              bottom: 0;
              z-index: -1;
              width: 12px;
              height: 1px;
              background: #ee2c3c;
              transition: all 0.3s ease-in-out;
            }
          }
        }
        &:hover,
        &:focus {
          .list-thumb {
            img {
              transform: scale(1.1);
            }
          }
          .list-cont {
            &-more {
              color: #ee2c3c;
              &::before {
                background: #ee2c3c;
              }
              /*&::after {
                transform-origin: right bottom;
                transform: rotate(45deg);
              }*/
            }
          }
        }
      }
      li + li {
        margin-left: 120px;
      }
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: visible;
    circle {
      fill: $red;
    }
  }
</style>
